import api from '~/config/api'
import { baseChargeIntegrations } from '~/config/basePath'
import { ContractPixInterface } from '~/models/interfaces/ContractPix'

const pathUrl = `${baseChargeIntegrations}/v1/contracts`

const getPixData = async (id: string, installmentNumber: string): Promise<ContractPixInterface> => {
  const { data } = await api.get<ContractPixInterface>(`${pathUrl}/${id}/${installmentNumber}/pix`)

  return data
}

export { getPixData }
