import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import logo from '~/assets/images/logo.svg'

import { Content, Header, Loader, Main, Ol } from './styles'
import { breakpoints, typography } from '~/assets/styles'

import { Button, CopyButton, Flex, Typography } from '~/components/atoms'

import { getPixData, getQrCode } from '~/services/Pix'
import { getPixData as getPixDataContract } from '~/services/Contract'

import PixLinkTypeEnum from '~/models/enums/PixLinkTypeEnum'

import { useQuery } from '~/utils/useQuery'

export type PixDataProps = {
  clientName: string
  dueDate: string
  pixId: string
  qrCodeData: string
  value: number
}

type ParamsProps = {
  id: string
}

export const Pix = () => {
  const { id } = useParams<ParamsProps>()
  const { query } = useQuery()

  const [pixData, setPixData] = useState<PixDataProps>()
  const [pixQrCode, setPixQrCode] = useState<string>()

  useEffect(() => {
    const handleGetPixData = async () => {
      const type = query.get('type')
      const installmentNumber = query.get('instNum')

      if (!type || type === PixLinkTypeEnum.Deal) {
        const data = await getPixData(id)
        setPixData(data)
        return
      }

      const pixDataContract = await getPixDataContract(id, installmentNumber as string)

      const data: PixDataProps = {
        clientName: pixDataContract.customerName,
        dueDate: pixDataContract.dueDate,
        pixId: pixDataContract.id,
        qrCodeData: pixDataContract.qrCodeData,
        value: pixDataContract.value,
      }

      setPixData(data)
    }

    handleGetPixData()
  }, [id, query])

  useEffect(() => {
    const handleGetQrCode = async () => {
      if (pixData) setPixQrCode(await getQrCode(pixData.pixId))
    }

    handleGetQrCode()
  }, [pixData])

  useEffect(() => {
    document.title = 'Pagamento por Pix - Viacerta'
  }, [])

  return (
    <>
      {pixData && pixQrCode ? (
        <>
          <Header>
            <img src={logo} alt="Viacerta Logo" title="Viacerta Logo" />
          </Header>

          <Content>
            <Main>
              <Typography
                color="grey.dark"
                variant="titleSmall"
                textTransform="capitalize"
                marginBottom={8}
                bold
              >
                {`Olá, ${pixData.clientName.split(' ')[0].toLowerCase()}`}
              </Typography>

              <Typography variant="text" color="grey.base">
                Aqui está sua negociação. Confira os dados abaixo e copie o
                código para realizar o pagamento.
              </Typography>

              <Flex
                gap={16}
                marginTop={16}
                sx={{
                  [`@media (max-width: ${breakpoints.sm}px)`]: {
                    flexDirection: 'column',
                  },
                }}
              >
                <Flex
                  width="100%"
                  borderRadius="small"
                  flexDirection="column"
                  gap={16}
                >
                  <Flex
                    flexWrap="wrap"
                    borderRadius="small"
                    backgroundColor="background.white"
                    gap={8}
                    padding={16}
                  >
                    <Typography
                      width="240px"
                      variant="text"
                      color="grey.dark"
                      sx={{
                        lineBreak: 'anywhere',
                        '& > strong': {
                          fontSize: typography.textLarge,
                          fontWeight: '700',
                          lineHeight: '120%',
                        },
                      }}
                    >
                      {'Vencimento: '}
                      <strong>
                        {new Date(pixData.dueDate).toLocaleDateString('pt-BR')}
                      </strong>
                    </Typography>

                    <Typography
                      variant="text"
                      color="grey.dark"
                      sx={{
                        lineBreak: 'anywhere',
                        '& > strong': {
                          fontSize: typography.textLarge,
                          fontWeight: '700',
                          lineHeight: '120%',
                        },
                      }}
                    >
                      {'Valor: '}
                      <strong>
                        {pixData.value.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </strong>
                    </Typography>
                  </Flex>

                  <Flex
                    borderRadius="small"
                    backgroundColor="background.white"
                    padding={16}
                  >
                    <Typography
                      variant="textSmall"
                      color="grey.base"
                      sx={{ lineBreak: 'anywhere' }}
                    >
                      {pixData.qrCodeData}
                    </Typography>
                  </Flex>

                  <CopyButton
                    size="large"
                    variant="contained"
                    value={pixData.qrCodeData}
                    fullWidth
                  >
                    Copiar código Pix
                  </CopyButton>
                </Flex>

                <Flex
                  minWidth="313px"
                  alignItems="center"
                  borderRadius="small"
                  flexDirection="column"
                  justifyContent="center"
                  backgroundColor="background.system"
                  sx={{
                    [`@media (max-width: ${breakpoints.md}px)`]: {
                      minWidth: '200px',
                    },
                    [`@media (max-width: ${breakpoints.sm}px)`]: {
                      minWidth: '100%',
                    },
                  }}
                >
                  <img
                    src={`data:image/jpeg;base64,${pixQrCode}`}
                    alt="QR Code"
                    title="QR Code"
                  />
                </Flex>
              </Flex>

              <Flex
                gap={16}
                marginTop={16}
                sx={{
                  [`@media (max-width: ${breakpoints.md}px)`]: {
                    flexDirection: 'column',
                  },
                }}
              >
                <Flex
                  width="100%"
                  borderRadius="small"
                  flexDirection="column"
                  backgroundColor="background.white"
                  padding={16}
                >
                  <Typography variant="text" color="grey.dark" bold>
                    Instruções para fazer o pagamento por Pix:
                  </Typography>

                  <Ol>
                    <Typography as="li" variant="text" color="grey.base">
                      Abra o aplicativo do seu banco no celular.
                    </Typography>

                    <Typography as="li" variant="text" color="grey.base">
                      Procure a opção de pagamentos ou transferências.
                    </Typography>

                    <Typography as="li" variant="text" color="grey.base">
                      {`Escolha a forma de pagamento Pix e selecione "Pix Copia e
              Cola".`}
                    </Typography>

                    <Typography as="li" variant="text" color="grey.base">
                      Cole o código Pix Copia e Cola na caixa de texto.
                    </Typography>

                    <Typography as="li" variant="text" color="grey.base">
                      Verifique se o valor está correto e confirme o pagamento.
                    </Typography>
                  </Ol>
                </Flex>

                <Flex
                  minWidth="313px"
                  textAlign="center"
                  alignItems="center"
                  borderRadius="small"
                  flexDirection="column"
                  backgroundColor="background.white"
                  gap={16}
                  padding={16}
                  sx={{
                    [`@media (max-width: ${breakpoints.md}px)`]: {
                      minWidth: '100%',
                    },
                  }}
                >
                  <Typography
                    variant="title"
                    color="grey.dark"
                    lineHeight="120%"
                    bold
                  >
                    Ficou com dúvida?
                  </Typography>

                  <Typography variant="text" color="grey.base">
                    Para ver todas as suas negociações acesse o Portal de
                    Negociação.
                  </Typography>

                  <Button
                    as="a"
                    href="https://negociacao.viacertabanking.com.br"
                    target="_blank"
                    size="large"
                    variant="outlined"
                    sx={{
                      [`@media (max-width: ${breakpoints.sm}px)`]: {
                        width: '100%',
                      },
                    }}
                  >
                    Acessar portal
                  </Button>
                </Flex>
              </Flex>
            </Main>
          </Content>
        </>
      ) : (
        <Loader>
          <Typography variant="titleSmall" color="grey.dark" lineHeight="150%">
            Carregando...
          </Typography>
        </Loader>
      )}
    </>
  )
}
